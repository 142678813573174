@charset "UTF-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    font-family: 'Poppins', Arial, sans-serif;
    max-height: 100%;
    max-width: 100%;
    position: static;
    background-color: #fff;
}

h1 {
    margin: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

hr {
    width: 300px;
    margin: 0 auto;
    display: none;
}

code {
    font-family: "Courier New", Courier, monospace !important;
    color: white;
    background: #414141;
    border-radius: 2px;
    padding: 2.5px;
}

ul {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.message {
    padding: 20px;
    border-radius: 5px;
}
.error {
    background: #FF0000;
    color: #ffffff;
}

h3.txt-h-title {
    padding: 0 10px;
    color: #ffffff;
    font-size: 1.5em;
    margin-block: 0;
}
.txt-h-desc {
    color: #c0c0cc;
    font-size: .9rem;
}
.txt-h {
    display: flex;
    justify-content: left;
    align-items: center;
    font-size: 14px;
    line-height: 1.57;
    padding: 10px 30px;
    white-space: nowrap;
}
.txt-h img {
    height: 20px;
    width: auto;
    border-radius: 50%;
}

/* Big Discord button #VIP / #Certification */
._y4qsg2jkuln {
    margin: 50px 0;
}
._y4qsg2jkuln button {
    background: #1b1c1d;
    color: #ffffff;
    border: none;
    border-radius: 50px;
    padding: 20px 30px;
    transition-duration: .5s;
}
._y4qsg2jkuln button:focus {
    outline: none;
}
._y4qsg2jkuln button:hover {
    background: #323232;
    transition-duration: .4s;
}
._y4qsg2jkuln a {
    background: #1b1c1d;
    color: #ffffff;
    border-radius: 50px;
    padding: 20px 30px;
    transition-duration: .5s;
}
._y4qsg2jkuln a:hover {
    background: #323232;
    transition-duration: .4s;
}
/* Big Discord button #VIP / #Certification */

/* Discord Embed */
.embed {
    max-width: 500px;
    background: #202225;
    border-left: 3px solid #000000;
    border-radius: 5px;
}
.embed > .content {
    padding: 10px 15px;
}
.embed > .content > .title {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 5px;
}
.embed > .content > .title input.title {
    width: 100%!important;
    height: auto!important;
    margin: 0!important;
    padding: 0!important;
    background: none!important;
    color: #ffffff;
    border: none!important;
    border-radius: unset!important;
    max-height: unset!important;
}
.embed > .content > .title input.url {
    width: auto!important;
    height: auto!important;
    margin: 5px 0 0 0 !important;
    padding: 2px!important;
    background: #000000!important;
    color: #ffffff;
    border: none!important;
    border-radius: unset!important;
    max-height: unset!important;
    font-size: 14px;
    font-style: italic;
}
.embed > .content > .description > .textArea {
    width: 100%;
    min-height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
}
.embed > .content > .description > .textArea > .wordCount {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #ffffffb3;
    user-select: none;
}
.embed > .content > .description > .textArea textarea {
    background: none;
    color: #ffffff;
    border: none;
    min-height: 150px;
    margin-top: 1em;
    resize: none;
}
.embed > .content > .description > .textArea textarea:focus {
    outline: none;
}
.embed > .content > .fields {
    margin-top: 10px;
}
.embed > .content > .fields > .field > .title {
    color: #ffffff;
    font-weight: 600;
}
.embed > .content > .fields > .field > .desc {
    color: #c0c0cc;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.embed > .content > .fields > .field > .desc img {
    max-height: 20px;
    margin-right: 5px;
}
.embed > .content > .footer {
    margin-top: 10px;
    display: flex;
    align-content: center;
    color: #ffffff;
}
.embed > .content > .footerNoFlex {
    margin: 5px 0;
    color: #ffffff;
}
.embed > .content > .footer > .author, .embed > .content > .footerNoFlex > .author {
    color: #c0c0cc;
    font-size: .8rem;
    margin-right: 5px;
}
.embed > .content > .footerNoFlex > .author input {
    width: 100%!important;
    height: auto!important;
    margin: 0!important;
    padding: 0!important;
    background: none!important;
    color: #ffffff;
    border: none!important;
    border-radius: unset!important;
    max-height: unset!important;
}
.embed > .content > .footer > .time {
    color: #c0c0cc;
    font-size: .8rem;
    margin-left: 5px;
}
/* Discord Embed */

input[disabled], textarea[disabled], button[disabled] {
    cursor: not-allowed!important;
    background: #171818 !important;
    border: unset !important;
}

div[disabled] {
    display: none;
}

.save {
    position: fixed;
    width: 100%;
    transition: bottom 700ms cubic-bezier(0.70, -0.60, 0.265, 1.60) 0s, background-color 400ms ease-out 0s;
}
.save > .pre {
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 10px;
}
.save > .pre > .txt {
    background: #000000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 5px 10px;
    box-shadow: #141517bd 0 0 10px;
}
.save span {
    color: #ffffff;
}
.save button {
    transition-duration: 0.3s;
    color: #ffffff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 30px;
    margin: 0 2px;
}
.save button.btn_save {
    background: #3eb386;
}
.save button.btn_cancel {
    border: 2px solid #1b1c1d;
    background: none;
}
.save button.btn_save:hover {
    background: #288659;
    transition-duration: 0.3s;
}
.save button.btn_cancel:hover {
    border: 2px solid #606060;
    transition-duration: 0.3s;
}
.save button:focus {
    outline: none;
}
@media (max-width: 710px) {
    .save > .pre > .txt {
        flex-direction: column;
        text-align: center;
    }
    .save > .pre > .txt div {
        margin-top: 5px;
    }
}

#check {
    color: #48c28a;
}
#cross {
    color: #cc3e4a;
}

/*.loader {
    background: #1b1c1d;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader > .childLoader {
    position: absolute;
    padding: 50px;
    border: solid 10px #ffffff;
    border-top: solid 10px #febd3d;
    border-radius: 50%;
    animation: loadingAnimation 4s linear infinite;
}

@keyframes loadingAnimation {
    0% {
        -webkit-transform: rotate(0);
        transform:rotate(0);
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        -webkit-transform: rotate(900deg);
        transform:rotate(900deg);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        -webkit-transform: rotate(1800deg);
        transform:rotate(1800deg);
    }
}*/

.loader {
    background: #11233c;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader > .childLoader {
    width: 100%;
    text-align: center;
    font-size: 5em;
    color: #11233c;
    text-transform: uppercase;
    font-weight: 700;
    animation: loadingAnimation 4s linear infinite;
}
.loader > .childLoader::before {
    content: "EP21";
}
@media (max-width: 530px) {
    .loader > .childLoader {
        font-size: 4em;
    }
}
@media (max-width: 430px) {
    .loader > .childLoader {
        font-size: 3em;
    }
}
@media (max-width: 330px) {
    .loader > .childLoader {
        font-size: 2em;
    }
}
@keyframes loadingAnimation {
    0% {
        color: #ffffff0a;
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    60% {
        color: #ffffff;
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        color: #ffffff0a;
    }
}

.smallLoader {
    width: 0;
    height: 0;
    padding: 5px;
    border: solid 5px transparent;
    border-top: solid 5px #ffffff;
    border-bottom: solid 5px #ffffff;
    border-radius: 50%;
    animation: smallLoadingAnimation 2s linear infinite;
}

@keyframes smallLoadingAnimation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}